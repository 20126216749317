import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Form, Input, Radio, Row, Col, Select, message } from 'antd';
import ConfirmBtns from './components/ConfirmBtns';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import './index.less';
const { peopleRelation, marital, credentialType, degreeEducation, nation, sex } = Dict.map;
const Option = Select.Option;
const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const Title = Loader.loadBusinessComponent('SystemTitle');
const FormUpload = Loader.loadBusinessComponent('UploadComponents', 'UploadSingleFile');
const WrapperView = Loader.loadBusinessComponent('SystemWrapper');

@withRouter
@Decorator.businessProvider('roleManagement', 'tab', 'user')
@Decorator.withEntryLog()
@observer
class PeopleEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      imageUrl: ''
    };
  }

  componentWillMount() {
    let { location } = this.props;
    let id = Utils.queryFormat(location.search).id;
    LM_DB.get('parameter', id).then(data => {
      let personData = this.handleData(_.cloneDeep(data.data));
      this.setState({
        data: personData
      });
    });
  }

  /**
   * @desc 处理后台返回的数据，将汉字转化为对应的code
   */
  handleData = data => {
    const dataSource = [
      { source: sex, keyWord: 'gender' }, //性别
      { source: credentialType, keyWord: 'identityCardType' }, //证件类型
      { source: nation, keyWord: 'nationality' }, //民族类型
      { source: marital, keyWord: 'maritalStatus' }, //婚姻状态
      { source: degreeEducation, keyWord: 'educationDegree' }, //文化程度
      { source: peopleRelation, keyWord: 'resideType' } //身份类别
    ];
    dataSource.map(item => {
      item.source.forEach(v => {
        if (v.label === data[item.keyWord]) {
          data[item.keyWord] = v.value; // 将汉字转为对应的code
        }
      });
    });
    return data;
  };

  /**
   * @desc 上传图片
   */
  onUploadChange = value => {
    this.props.form.setFieldsValue({
      portraitPicUrl: value.url
    });
    this.setState({
      imageUrl: value.url
    });
  };
  /**
   * 获取字典
   */
  getMapType = type => {
    switch(type){
      case 'identityCardType':
        return {data:credentialType,msg:'请选择正确的证件类型'};
      case 'maritalStatus':
          return {data:marital,msg:'请选择正确的婚姻状况'};
      case 'educationDegree':
          return {data:degreeEducation,msg:'请选择正确的文化程度'};
      case 'resideType':
          return {data:peopleRelation,msg:'请选择正确的身份类别'};
      case 'nationality':
        return {data:nation,msg:'请选择正确的民族'};
      default :
        return {data:[],msg:''}
    }
  }

  /**
   * 自定义校验
   */
  handleConfirmPassword = (rule, value, callback) => {
    let errors = []
    let {data,msg} = this.getMapType(rule.field)
    let values = data.map(v => v.value)
    if (!!value && !values.includes(value)) {
      errors.push(msg)
    }
    callback(errors)
  }

  /**
   * @desc 提交form表单
   */
  submitUserForm = () => {
    const { form } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return;
      }
      let { imageUrl, data } = this.state;
      if (imageUrl) {
        values.portraitPicUrl = imageUrl;
      } else {
        values.portraitPicUrl = undefined;
      }
      values.villageId = data.villageId;
      values.id = data.id;
      Service.person
        .updatePerson(values)
        .then(res => {
          if (res.code === 0) {
            message.success('人员编辑成功');
            SocketEmitter.emit(SocketEmitter.eventName.updateCommunityPeople, values);
            this.cancel();
          }
        })
        .catch(err => message.error(err.data.message));
    });
  };

  /**
   *  @desc 取消编辑
   */
  cancel = () => {
    const { location, tab } = this.props;
    tab.closeCurrentTab({ location });
  };

  identityInputOnFocus = () => {
    const { data } = this.state;
    const { form } = this.props;
    form.setFieldsValue({ identityCardNumber: '' });
    data.identityCardNumber = '';
    this.setState({ data });
    this.identityInputOnFocus = null;
  };

  mobileInputOnFocus = () => {
    const { data } = this.state;
    const { form } = this.props;
    form.setFieldsValue({ mobile: '' });
    data.mobile = '';
    this.setState({ data });
    this.mobileInputOnFocus = null;
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    let { data, imageUrl } = this.state;
    const image = (
      <div className="user-avatar">
        <img src={imageUrl} alt="" />
      </div>
    );
    const initImageDom = (
      <div className="user-avatar">
        <img src={data.portraitPictureUrl} alt="" />
      </div>
    );
    return (
      <WrapperView name={`${data.personName}`} width="100%">
        <div className="people-edit-view">
          <div className="userForm">
            <Form layout="vertical" className="ant-form-sm">
              <div className="personnel-info">
                <Title name="人员信息" />
                <Row>
                  <Col span={24}>
                    <FormItem className="uploadForm" label="上传照片:" ref={view => (this.formUpload = view)}>
                      {getFieldDecorator('portraitPicUrl', {
                        initialValue: data.portraitPictureUrl,
                        rules: [{ required: true, message: '请上传照片' }]
                      })(
                        <FormUpload
                          name="portraitPicUrl"
                          uploadDone={this.onUploadChange}
                          uploadService={Service.user.uploadImg}
                          uploadTip={false}
                          children={!!imageUrl && !!!imageUrl.file ? image : data.portraitPictureUrl ? initImageDom : ''}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <FormItem label="姓名:">
                      {getFieldDecorator('personName', {
                        rules: [{ required: true, message: '姓名必须填写' }, { max: 20, message: '姓名最大长度20' }],
                        initialValue: data.personName || ''
                      })(<Input name="personName" type="text" placeholder="请填写名称" size="small" />)}
                    </FormItem>
                    <FormItem label="性别:">
                      {getFieldDecorator('gender', {
                        // rules: [{ required: true, message: '性别必须填写' }],
                        initialValue: data.gender && data.gender
                      })(
                        <RadioGroup name="gender" className="ant-checkbox-small">
                          {sex.map(item => (
                            <Radio key={item.value} value={item.value}>
                              {item.label}
                            </Radio>
                          ))}
                        </RadioGroup>
                      )}
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <FormItem label="证件类型:">
                      {getFieldDecorator('identityCardType', {
                        validateTrigger: "onChange",
                        rules:[
                          { required: false },
                          {validator: this.handleConfirmPassword}
                        ],
                        initialValue: data.identityCardType && data.identityCardType
                      })(
                        <Select placeholder="请选择证件类型">
                          {credentialType.map(item => (
                            <Option key={item.value} value={item.value}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </FormItem>
                    <FormItem label="证件号码:">
                      {getFieldDecorator('identityCardNumber', {
                        rules: [
                          {
                            required: false,
                            // pattern: /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
                            message: '请填写正确的身份证号码'
                          }
                        ],
                        initialValue: data.identityCardNumber && data.identityCardNumber
                      })(<Input onFocus={this.identityInputOnFocus} name="identityCardNumber" type="text" placeholder="请填写名称" size="small" />)}
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <FormItem label="手机号码:">
                      {getFieldDecorator('mobile', {
                                 rules: [
                                  {
                                    required: false,
                                    pattern: /^1[3456789]\d{9}$/,
                                    message: '请填写正确的手机号码'
                                  }
                                ],
                        initialValue: data.mobile && data.mobile
                      })(<Input onFocus={this.mobileInputOnFocus} name="mobile" type="text" placeholder="请填写名称" size="small" />)}
                    </FormItem>
                    <FormItem label="民族:">
                      {getFieldDecorator('nationality', {
                        validateTrigger: "onChange",
                        rules:[
                          { required: false },
                          {validator: this.handleConfirmPassword}
                        ],
                        initialValue: data.nationality && data.nationality
                      })(
                        <Select placeholder="请选择民族">
                          {nation.map(item => (
                            <Option key={item.value} value={item.value}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <FormItem label="婚姻状况:">
                      {getFieldDecorator('maritalStatus', {
                        validateTrigger: "onChange",
                         rules:[
                          {required:false},
                          {validator:this.handleConfirmPassword}
                        ],
                        initialValue: data.maritalStatus && data.maritalStatus
                      })(
                        <Select placeholder="请选择婚姻状况">
                          {marital.map(item => (
                            <Option key={item.value} value={item.value}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </FormItem>
                    <FormItem label="文化程度:">
                      {getFieldDecorator('educationDegree', {
                        validateTrigger: "onChange",
                         rules:[
                          {required:false},
                          {validator:this.handleConfirmPassword}
                        ],
                        initialValue: data.educationDegree && data.educationDegree
                      })(
                        <Select placeholder="请选择文化程度">
                          {degreeEducation.map(item => (
                            <Option key={item.value} value={item.value}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <FormItem label="身份类别:">
                      {getFieldDecorator('resideType', {
                        validateTrigger: "onChange",
                         rules:[
                          {required:false},
                          {validator:this.handleConfirmPassword}
                        ],
                        initialValue: data.resideType && data.resideType
                      })(
                        <Select placeholder="请选择身份类型">
                          {peopleRelation.map(item => (
                            <Option key={item.value} value={item.value}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </div>
              <div className="housing-info">
                <Title name="人员信息" />
                <Col span={24}>
                  <FormItem label="楼栋号:">
                    {getFieldDecorator('buildingNo', {
                      rules: [{ required: true, message: '楼栋号必须填写' }],
                      initialValue: data.buildingNo && data.buildingNo
                    })(<Input name="buildingNo" type="text" placeholder="请填写楼栋号" size="small" />)}
                  </FormItem>
                  <FormItem label="单元号:">
                    {getFieldDecorator('unitNo', {
                      rules: [{ required: true, message: '单元号必须填写' }],
                      initialValue: data.unitNo && data.unitNo
                    })(<Input name="unitNo" type="text" placeholder="请填写单元号" size="small" />)}
                  </FormItem>
                </Col>
                <Col span={24}>
                  <FormItem label="房间号:">
                    {getFieldDecorator('houseNo', {
                      rules: [{ required: true, message: '房间号必须填写' }],
                      initialValue: data.houseNo && data.houseNo
                    })(<Input name="houseNo" type="text" placeholder="请填写房间号" size="small" />)}
                  </FormItem>
                  <FormItem label="房屋面积:">
                    {getFieldDecorator('area', {
                      initialValue: data.area && data.area
                    })(<Input name="area" type="text" placeholder="请填写房屋面积" size="small" />)}
                  </FormItem>
                </Col>
              </div>
              {/* <div className="vehicle-info">
                <Title name="车辆信息" />
                <Col span={24}>
                  <FormItem label="车牌号:">
                    {getFieldDecorator('vehiclePlateNums', {
                      initialValue: data.vehiclePlateNums && data.vehiclePlateNums.join(',')
                    })(<Input disabled name="vehiclePlateNums" type="text" placeholder="请填写车牌号" size="small" />)}
                  </FormItem>
                </Col>
              </div> */}
            </Form>
          </div>
          <ConfirmBtns className="setting-edit-btns" cancelUserForm={this.cancel} submitUserForm={this.submitUserForm} />
        </div>
      </WrapperView>
    );
  }
}

export default Form.create()(PeopleEdit);
